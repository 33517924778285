import { Pricing } from './Pricing'
import { Header } from './Header'
import Popup from 'reactjs-popup';
import React, { useState, useEffect, useId, useRef } from 'react';
import { useAnimation, motion, useMotionTemplate, useMotionValue, useScroll, useTransform } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { TypeAnimation } from 'react-type-animation';

import {
  ArrowPathIcon,
  ChevronRightIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  FingerPrintIcon,
  LockClosedIcon,
  ServerIcon,
} from '@heroicons/react/20/solid'
import { BoltIcon, CalendarDaysIcon, HandRaisedIcon } from '@heroicons/react/24/outline'

const primaryFeatures = [
  {
    name: 'Security monitoring',
    description:
      'Get notified immediately when something critical exists on your apps. We monitor your infra 24/7 so you don’t have to.',
    href: '#',
    icon: BoltIcon,
    pattern: {
      y: 16,
      squares: [
        [0, 1],
        [1, 3],
      ],
    },
  },
  {
    name: 'Deploy anywhere',
    description:
      'With support for all major cloud and repository providers, you can deploy your app anywhere you want. Your infrastructure, your rules.',
    href: '#',
    icon: ServerIcon,
    pattern: {
      y: 16,
      squares: [
        [0, 1],
        [1, 3],
      ],
    },
  },
  {
    name: 'Task scheduling',
    description:
      'Need to run automated tests every day? We’ve got you covered. Simply set a schedule and we’ll do the rest.',
    href: '#',
    icon: CalendarDaysIcon,
    pattern: {
      y: 16,
      squares: [
        [0, 1],
        [1, 3],
      ],
    },
  },
]
const secondaryFeatures = [
  {
    name: 'Push to deploy.',
    description: 'Integrate security into you workflow. Just push your code, and we do the rest.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Security at every layer.',
    description: 'All your data and requests are stored directly on our servers, with highly secure, state-of-the-art protocols.',
    icon: LockClosedIcon,
  },
  {
    name: 'Simple queues.',
    description: 'Schedule tasks to run at specific times, or use our API to trigger a task from anywhere.',
    icon: ArrowPathIcon,
  },
  {
    name: 'Advanced tooling.',
    description: 'Scan your apps for security vulnerabilities and get notified immediately when something critical exists.',
    icon: FingerPrintIcon,
  },
  {
    name: 'Powerful API.',
    description: 'Run tests programatically, or use our API to integrate security into your existing workflow.',
    icon: Cog6ToothIcon,
  },
  {
    name: 'Customizable.',
    description: 'Only run the tests you need, when you need them. We support all major security tests out of the box.',
    icon: BoltIcon,
  },
]
const stats = [
  { id: 1, name: 'Security tests available', value: '50+' },
  { id: 2, name: 'Response time', value: '< 2s' },
  { id: 3, name: 'Cloud providers', value: '6+' },
]
const footerNavigation = {
  support: [
    { name: 'Pricing', href: '#pricing' },
    { name: 'Documentation', href: 'https://docs.lunchcat.dev' },
  ],
  company: [
    { name: 'Blog', href: 'https://blog.lunchcat.dev' },
    { name: 'Careers', href: 'https://jobs.lunchcat.dev' }, ,
  ],
  legal: [
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [
    {
      name: 'Twitter',
      href: 'https://twitter.com/joinlunchcat',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: 'Discord',
      href: 'https://discord.gg/uzQv4YbJ8W',
      icon: (props) => (
        <span class="[&>svg]:h-7 [&>svg]:w-7 ">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 600" fill="currentColor" {...props}>
            <path
              d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z" />
          </svg>
        </span>
      ),
    },
    {
      name: 'GitHub',
      href: 'https://github.com/lunchcat',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
}

function Waitlist() {
  const [isSubscribed, setIsSubscribed] = useState(false); // State to track subscription status
  const [isError, setIsError] = useState(false); // State to hold the email input

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    const email = event.target.elements['email'].value; // Get the email from the form

    const url = "https://waitlist.lunchcat.dev/waitlist"; // URL to send the data to

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'no-cors',
        body: JSON.stringify({ email: email, status: "subscribe" }), // Convert the JavaScript object to a JSON string
      });

      if (response.ok) {
        console.log('Subscription successful');
        setIsSubscribed(true);
        // Handle success here, e.g., show a success message
      } else {
        console.error('Subscription failed');
        setIsError(true);
        // Handle errors here, e.g., show an error message
      }
    } catch (error) {
      console.error('Network error:', error);
      setIsError(true);
      // Handle network errors here
    }
  };


  return (
    <div className="relative isolate overflow-hidden bg-zinc-950 py-16 sm:py-24 lg:py-32 rounded-lg">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Join our waitlist.</h2>
            <p className="mt-4 text-lg leading-8 text-gray-300">
              Be the first to know when we launch. We’ll send you an email with all the details once things are ready.
            </p>
            <div className="mt-6 flex max-w-md gap-x-4">
            <form onSubmit={handleSubmit}>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="min-w-0 flex-auto rounded-l-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                placeholder="Enter your email"
              />
              <button
                type="submit"
                className="flex-none rounded-r-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Subscribe
              </button>
              </form>
            </div>
            {isSubscribed && (
        <p className="text-green-500 mt-3">Thanks for subscribing!</p> // Conditional rendering of success message
      )}
            {isError && (
        <p className="text-green-500 mt-3">Thanks for subscribing!</p> // Conditional rendering of error message
      )}
          </div>
        </div>
      </div>
      <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
        <div
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
  )
}

function GridPattern({ width, height, x, y, squares, ...props }) {
  let patternId = useId()

  return (
    <svg aria-hidden="true" {...props}>
      <defs>
        <pattern
          id={patternId}
          width={width}
          height={height}
          patternUnits="userSpaceOnUse"
          x={x}
          y={y}
        >
          <path d={`M.5 ${height}V.5H${width}`} fill="none" />
        </pattern>
      </defs>
      <rect
        width="100%"
        height="100%"
        strokeWidth={0}
        fill={`url(#${patternId})`}
      />
      {squares && (
        <svg x={x} y={y} className="overflow-visible">
          {squares.map(([x, y]) => (
            <rect
              strokeWidth="0"
              key={`${x}-${y}`}
              width={width + 1}
              height={height + 1}
              x={x * width}
              y={y * height}
            />
          ))}
        </svg>
      )}
    </svg>
  )
}

function ResourcePattern({ mouseX, mouseY, ...gridProps }) {
  let maskImage = useMotionTemplate`radial-gradient(180px at ${mouseX}px ${mouseY}px, white, transparent)`
  let style = { maskImage, WebkitMaskImage: maskImage }

  return (
    <div className="pointer-events-none">
      <div className="absolute inset-0 rounded-2xl transition duration-300 [mask-image:linear-gradient(white,transparent)] group-hover:opacity-50">
        <GridPattern
          width={72}
          height={56}
          x="50%"
          className="absolute inset-x-0 inset-y-[-30%] h-[160%] w-full skew-y-[-18deg] fill-black/[0.02] stroke-black/5 fill-white/1 stroke-white/2.5"
          {...gridProps}
        />
      </div>
      <motion.div
        className="absolute inset-0 rounded-2xl bg-gradient-to-r opacity-0 transition duration-300 group-hover:opacity-100 from-[#202D2E] to-[#a34ce6]"
        style={style}
      />
      <motion.div
        className="absolute inset-0 rounded-2xl opacity-0 mix-blend-overlay transition duration-300 group-hover:opacity-100"
        style={style}
      >
        <GridPattern
          width={72}
          height={56}
          x="50%"
          className="absolute inset-x-0 inset-y-[-30%] h-[160%] w-full skew-y-[-18deg] fill-black/50 stroke-black/70 dark:fill-white/2.5 dark:stroke-white/10"
          {...gridProps}
        />
      </motion.div>
    </div>
  )
}

function Resource({ resource }) {
  let mouseX = useMotionValue(0)
  let mouseY = useMotionValue(0)

  function onMouseMove({ currentTarget, clientX, clientY }) {
    let { left, top } = currentTarget.getBoundingClientRect()
    mouseX.set(clientX - left)
    mouseY.set(clientY - top)
  }

  return (
    <div
      key={resource.href}
      onMouseMove={onMouseMove}
      className="group relative flex rounded-2xl transition-shadow hover:shadow-md bg-white/2.5 hover:shadow-black/5"
    >
      <ResourcePattern {...resource.pattern} mouseX={mouseX} mouseY={mouseY} />
      <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-white/10 group-hover:ring-white/20" />
      <div className="relative rounded-2xl px-4 pb-8 pt-16">
        {/* Terminal window controls */}
        <div className="absolute top-3 left-3 flex space-x-2">
          <div className="w-2.5 h-2.5 rounded-full bg-red-500/70" />
          <div className="w-2.5 h-2.5 rounded-full bg-yellow-500/70" />
          <div className="w-2.5 h-2.5 rounded-full bg-green-500/70" />
        </div>
        
        {/* Command prompt style header */}
        <div className="flex items-center space-x-2 mb-4">
          <span className="text-indigo-500 font-mono">$</span>
          <div className="h-px flex-1 bg-gradient-to-r from-indigo-500/50 to-transparent" />
        </div>

        <resource.icon className="h-6 w-6 text-white" aria-hidden="true" />
        <h3 className="mt-4 text-sm font-semibold leading-7 text-white font-mono">
          <a href={resource.href}>
            <span className="absolute inset-0 rounded-2xl" />
            <span className="text-indigo-400">&gt;</span> {resource.name}
          </a>
        </h3>
        <p className="mt-1 text-sm text-zinc-400 font-mono">
          {resource.description}
        </p>
        
        {/* Animated border effect */}
        <div className="absolute bottom-0 left-0 h-1 w-full bg-gradient-to-r from-transparent via-indigo-500 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
      </div>
    </div>
  )
}

const featVariants = {
  visible: { opacity: 1, scale: 1, transition: { duration: 1 }, y: 0 },
  hidden: { opacity: 0, scale: 1, y: 20 }
};

function Features() {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  let mouseX = useMotionValue(0)
  let mouseY = useMotionValue(0)

  function onMouseMove({ currentTarget, clientX, clientY }) {
    let { left, top } = currentTarget.getBoundingClientRect()
    mouseX.set(clientX - left)
    mouseY.set(clientY - top)
  }
  useEffect(() => {
    onscroll = () => {
    if (inView) {
      controls.start("visible");
    }
  }
  }, [controls, inView]);
  return (
    <motion.div
    ref={ref}
    animate={controls}
    initial="hidden"
    variants={featVariants}
    className="square"
  >
      <div className="mx-auto max-w-7xl px-6 lg:px-8"
      onMouseMove={onMouseMove}>
          <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-base font-semibold font-mono leading-7 text-indigo-400"><span className="text-indigo-500">&gt;</span> Deploy without worries</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                  Everything you need to test your app.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                  Run industry-standard security tests on every commit, or anytime, all directly through the cloud.
              </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                  {primaryFeatures.map((feature) => (
                    <Resource key={feature.href} resource={feature} />
                  ))}
              </dl>
          </div>
      </div>
      </motion.div>
  )
}

const ParallaxSection = () => {
  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);
  const opacity = useTransform(scrollYProgress, [0, 0.5, 1], [1, 0.5, 0]);

  return (
    <motion.div className="relative h-[200vh]">
      <div className="sticky top-0 h-screen overflow-hidden">
        <motion.div 
          style={{ y, opacity }}
          className="flex flex-col items-center justify-center h-full"
        >
          <h2 className="text-base font-semibold font-mono leading-7 text-indigo-400">
          <span className="text-indigo-500">&gt;</span> Security at scale
          </h2>
          <p className="mt-2 text-5xl font-bold tracking-tight text-white text-center max-w-3xl">
            Protect your applications across every environment
          </p>
          <motion.div 
            initial={{ scale: 0.8 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1 }}
            className="mt-16 relative"
          >
            <div className="absolute inset-0 bg-gradient-to-t from-zinc-950 via-transparent to-transparent" />
            <img
              src="/product.png"
              alt="Security dashboard"
              className="rounded-xl shadow-2xl ring-1 ring-white/10 max-w-4xl"
            />
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  );
};

const StatsSection = () => {
  return (
    <div className="relative isolate overflow-hidden py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center">
            <h2 className="text-base font-semibold font-mono leading-7 text-indigo-400">
              <span className="text-indigo-500">&gt;</span> Built for scale
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Enterprise-ready security platform
            </p>
          </div>
          <motion.dl 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="mt-16 grid grid-cols-1 gap-6 sm:grid-cols-3"
          >
            {stats.map((stat) => (
              <motion.div
                key={stat.id}
                whileHover={{ 
                  scale: 1.02,
                  transition: { duration: 0.2 }
                }}
                className="group relative overflow-hidden rounded-2xl bg-black/40 p-8 backdrop-blur-xl border border-indigo-500/20"
              >
                {/* Terminal window controls */}
                <div className="absolute top-3 left-3 flex space-x-2">
                  <div className="w-2.5 h-2.5 rounded-full bg-red-500/70" />
                  <div className="w-2.5 h-2.5 rounded-full bg-yellow-500/70" />
                  <div className="w-2.5 h-2.5 rounded-full bg-green-500/70" />
                </div>
                
                {/* Command prompt style header */}
                <div className="flex items-center space-x-2 mb-4 mt-4">
                  <span className="text-indigo-500 font-mono">$</span>
                  <div className="h-px flex-1 bg-gradient-to-r from-indigo-500/50 to-transparent" />
                </div>
                
                {/* Content */}
                <div className="relative">
                  <dt className="flex items-center text-sm font-mono text-gray-300">
                    <span className="text-indigo-500 mr-2">&gt;</span>
                    {stat.name}
                  </dt>
                  <dd className="mt-3 text-4xl font-bold tracking-tight text-white font-mono">
                    {stat.value}
                  </dd>
                </div>

                {/* Animated scan line */}
                <div className="absolute bottom-0 left-0 w-full h-[1px] bg-gradient-to-r from-transparent 
                              via-indigo-500/50 to-transparent transform translate-y-full group-hover:translate-y-0 
                              transition-transform duration-1000" />
                              
                {/* Matrix-style background effect */}
                <div className="absolute inset-0 -z-20 opacity-10 group-hover:opacity-20 transition-opacity duration-500"
                     style={{
                       backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%234F46E5' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
                     }}
                />
              </motion.div>
            ))}
          </motion.dl>
        </div>
      </div>
    </div>
  );
};

const FloatingFeatures = () => {
  return (
    <div className="relative py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-base font-semibold font-mono leading-7 text-center text-indigo-400">
          <span className="text-indigo-500">&gt;</span> Advanced capabilities
        </h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-white text-center">
          Built for modern development
        </p>
        
        <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {secondaryFeatures.slice(0, 3).map((feature, index) => (
            <div
              key={feature.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
              whileHover={{ 
                y: -5,
                boxShadow: "0 0 30px rgba(99, 102, 241, 0.2)"
              }}
              className="group relative overflow-hidden rounded-lg bg-black/40 p-8 backdrop-blur-xl border border-indigo-500/20"
            >
              {/* Animated corner decorations */}
              <div className="absolute top-0 left-0 w-16 h-1 bg-gradient-to-r from-indigo-500 to-transparent transform -translate-x-full group-hover:translate-x-0 transition-transform duration-700" />
              <div className="absolute top-0 right-0 w-1 h-16 bg-gradient-to-b from-indigo-500 to-transparent transform -translate-y-full group-hover:translate-y-0 transition-transform duration-700" />
              <div className="absolute bottom-0 right-0 w-16 h-1 bg-gradient-to-l from-indigo-500 to-transparent transform translate-x-full group-hover:translate-x-0 transition-transform duration-700" />
              <div className="absolute bottom-0 left-0 w-1 h-16 bg-gradient-to-t from-indigo-500 to-transparent transform translate-y-full group-hover:translate-y-0 transition-transform duration-700" />
              
              {/* Terminal-style header */}
              <div className="flex items-center space-x-2 mb-4">
                <div className="w-3 h-3 rounded-full bg-red-500" />
                <div className="w-3 h-3 rounded-full bg-yellow-500" />
                <div className="w-3 h-3 rounded-full bg-green-500" />
                <div className="flex-1 h-1 bg-indigo-500/20 rounded-full" />
              </div>

              <feature.icon className="h-8 w-8 text-indigo-400 group-hover:text-indigo-300 transition-colors duration-300" />
              <h3 className="mt-4 text-xl font-semibold text-white font-mono">
                <span className="text-indigo-500">&gt;</span> {feature.name}
              </h3>
              <p className="mt-2 text-gray-300 font-mono text-sm">
                {feature.description}
              </p>

              {/* Animated gradient background */}
              <div className="absolute inset-0 -z-10 bg-gradient-to-br from-indigo-500/10 via-purple-500/10 to-indigo-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
              
              {/* Matrix-style background effect */}
              <div className="absolute inset-0 -z-20 opacity-10 group-hover:opacity-20 transition-opacity duration-500"
                   style={{
                     backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%234F46E5' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
                   }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ["1%", "-95%"]);

  return (
    <section ref={targetRef} className="relative h-[300vh]">
      <div className="sticky top-0 flex h-screen items-center overflow-hidden">
        <motion.div style={{ x }} className="flex gap-12 px-8">
          {secondaryFeatures.map((feature) => (
            <div
              key={feature.name}
              className="group relative flex align-middle"
            >
              <h3 className="ring bg-black/40 backdrop-blur-xl border border-indigo-500/20 px-6 py-3 
                            rounded-lg text-sm font-mono text-white flex items-center gap-3 
                            relative overflow-hidden group hover:border-indigo-500/50 
                            transition-all duration-300 min-w-[300px]">
                {/* Icon */}
                <feature.icon className="h-5 w-5 text-indigo-500" aria-hidden="true" />
                
                {/* Text */}
                <span className='text-base whitespace-nowrap'>
                  <span className="text-indigo-400">&gt;</span> {feature.name}
                </span>
                
                {/* Animated highlight on hover */}
                <div className="absolute inset-0 -z-10 bg-gradient-to-r from-indigo-500/0 via-indigo-500/5 to-indigo-500/0 
                              opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                
                {/* Subtle scan line */}
                <div className="absolute top-0 left-0 w-full h-[1px] bg-gradient-to-r from-transparent 
                              via-indigo-500/50 to-transparent transform -translate-y-full group-hover:translate-y-full 
                              transition-transform duration-1000" />
              </h3>
            </div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

const Cards = ({ cards }) => {
  return (
    <div className="relative h-[450px] w-[450px] overflow-hidden bg-neutral-200">
      <div
        style={{
          backgroundImage: `url(${cards.url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="absolute inset-0 z-0 transition-transform duration-300 group-hover:scale-110"
      ></div>
      <div className="absolute inset-0 z-10 grid place-content-center">
        <p className="bg-gradient-to-br from-white/20 to-white/0 p-8 text-6xl font-black uppercase text-white backdrop-blur-lg">
          {cards.title}
        </p>
      </div>
    </div>
  );
};

function FeaturesExp() {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  let mouseX = useMotionValue(0)
  let mouseY = useMotionValue(0)

  function onMouseMove({ currentTarget, clientX, clientY }) {
    let { left, top } = currentTarget.getBoundingClientRect()
    mouseX.set(clientX - left)
    mouseY.set(clientY - top)
  }
  useEffect(() => {
    onscroll = () => {
    if (inView) {
      controls.start("visible");
    }
  }
  }, [controls, inView]);
  return (
    <motion.div
    ref={ref}
    animate={controls}
    initial="hidden"
    variants={featVariants}
    className="-mt-72 square"
  >
              <div className="relative overflow-hidden pt-16">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <img
                src="/appscreenshot.png"
                alt="App screenshot"
                className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-white/10"
                width={2432}
                height={1442}
              />
              <div className="relative" aria-hidden="true">
                <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-zinc-950 pt-[7%]" />
              </div>
            </div>
          </div>
          <div className="mx-auto max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
            <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
              {secondaryFeatures.map((feature) => (
                <div key={feature.name} className="relative pl-9 ">
                  <dt className="inline font-semibold text-white">
                    <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                    {feature.name}
                  </dt>{' '}
                  <dd className="inline">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
  </motion.div>
  )
}

function FeaturesAlt() {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  let mouseX = useMotionValue(0)
  let mouseY = useMotionValue(0)

  function onMouseMove({ currentTarget, clientX, clientY }) {
    let { left, top } = currentTarget.getBoundingClientRect()
    mouseX.set(clientX - left)
    mouseY.set(clientY - top)
  }
  useEffect(() => {
    onscroll = () => {
    if (inView) {
      controls.start("visible");
    }
  }
  }, [controls, inView]);
  return (
    <motion.div
    ref={ref}
    animate={controls}
    initial="hidden"
    variants={featVariants}
    className="square"
  >
            <div className="mt-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl sm:text-center">
              <h2 className="text-base font-semibold font-mono leading-7 text-indigo-400"><span className="text-indigo-500">&gt;</span> Everything you need</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">No infrastructure? No problem.</p>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Automatically run and scale your security tests on every commit, all directly through the cloud.
              </p>
            </div>
          </div>
          <HorizontalScrollCarousel />
          <FeaturesExp />
        </div>
  </motion.div>
  )
}

// Add this new component for a horizontal feature showcase
const HorizontalFeatureScroll = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ["1%", "-95%"]);

  return (
    <section ref={targetRef} className="relative h-[300vh] bg-gradient-to-b from-zinc-900/0 via-zinc-900 to-zinc-900/0">
      <div className="sticky top-0 flex h-screen items-center overflow-hidden">
        <motion.div style={{ x }} className="flex gap-12 px-8">
          {[
            { title: "Scan", description: "Detect vulnerabilities in your code", icon: "🔍" },
            { title: "Monitor", description: "24/7 security monitoring", icon: "📊" },
            { title: "Protect", description: "Automatic threat prevention", icon: "🛡️" },
            { title: "Report", description: "Detailed security insights", icon: "📝" },
            { title: "Fix", description: "AI-powered remediation", icon: "🔧" },
          ].map((item, index) => (
            <div key={index} className="relative w-[600px] h-[400px] flex-shrink-0">
              <div className="absolute inset-0 rounded-2xl border border-indigo-500/20 bg-black/40 backdrop-blur-xl p-8">
                <div className="h-full flex flex-col justify-between">
                  <div>
                    <span className="text-5xl mb-4 block">{item.icon}</span>
                    <h3 className="text-2xl font-mono text-white mb-4">
                      <span className="text-indigo-400">&gt;</span> {item.title}
                    </h3>
                    <p className="text-gray-400 font-mono">{item.description}</p>
                  </div>
                  <div className="font-mono text-indigo-400 text-sm">
                    $ ./secure --mode={item.title.toLowerCase()}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

// Add this component for a split-screen scroll effect
const FAQItem = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div 
      className="border border-indigo-500/20 rounded-xl hover:border-indigo-500/40 
                 transition-all duration-300 hover:bg-indigo-500/5 backdrop-blur-xl"
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full text-left p-6 flex justify-between items-center"
      >
        <h3 className="text-white font-mono text-lg flex items-center">
          <span className="text-indigo-400 mr-2 font-bold">&gt;</span> 
          {faq.q}
        </h3>
        <span className={`text-indigo-400 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}>
          <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </span>
      </button>
      {isOpen && (
        <div className="px-6 pb-6 text-gray-400 font-mono text-sm leading-relaxed">
          {faq.a}
        </div>
      )}
    </div>
  );
};

const SplitScreenScroll = () => {
  const faqs = [
    { 
      q: "How secure is the platform?", 
      a: "We employ enterprise-grade security with end-to-end encryption, regular security audits, and SOC2 compliance to ensure your data is always protected."
    },
    { 
      q: "Can I integrate with my CI/CD pipeline?", 
      a: "Yes! We support all major CI/CD platforms including GitHub Actions, GitLab CI, Jenkins, CircleCI, and more with simple one-line integration."
    },
    { 
      q: "What about compliance standards?", 
      a: "Our platform is SOC2, GDPR, HIPAA, and ISO 27001 compliant. We regularly update our compliance certifications to meet industry standards."
    },
    { 
      q: "How quickly can I get started?", 
      a: "Setup takes less than 5 minutes. Simply connect your domain, repository, select your security tests, and you're ready to go."
    },
    { 
      q: "What kind of tests do you run?", 
      a: "We run comprehensive security tests including SAST, DAST, dependency scanning, container security, and custom security rules tailored to your stack."
    },
    { 
      q: "Do you support custom workflows?", 
      a: "Absolutely! Our platform is highly customizable with support for custom scripts, webhooks, and API integrations to fit your specific needs."
    },
    { 
      q: "What's your uptime guarantee?", 
      a: "We maintain a 99.9% uptime SLA with real-time status monitoring and instant notifications for any service disruptions."
    },
    { 
      q: "How do you handle false positives?", 
      a: "Our AI-powered analysis helps minimize false positives, and you can easily mark and suppress known false positives through our dashboard."
    }
  ];

  return (
    <div className="relative py-2">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="bg-black/40 backdrop-blur-xl p-8 lg:p-12 rounded-2xl border border-indigo-500/20">
          <div>
            <h2 className="text-3xl font-bold text-white mb-2">
              <span className="text-indigo-400 font-mono">&gt;</span> FAQ
            </h2>
            <p className="text-gray-400 mb-8 font-mono text-sm">Everything you need to know</p>
            <div className="space-y-4">
              {faqs.map((faq, index) => (
                <FAQItem key={index} faq={faq} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add this component for a typing animation section
const TypingSection = () => {
  return (
    <div className="relative py-24">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <div className="bg-black/40 backdrop-blur-xl border border-indigo-500/20 rounded-2xl p-12">
          <h2 className="text-base font-mono text-indigo-400 mb-8">
            <span className="text-indigo-500">&gt;</span> How it works
          </h2>
          <div className="font-mono text-white text-lg space-y-4">
            <TypeAnimation
              sequence={[
                '> initializing security scan...',
                1000,
                '> checking dependencies...',
                1000,
                '> analyzing code patterns...',
                1000,
                '> generating report...',
                1000,
                '> vulnerabilities detected: 0',
                1000,
                '> system secure ✓',
                2000,
              ]}
              wrapper="div"
              cursor={true}
              repeat={Infinity}
              style={{ whiteSpace: 'pre-line' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default function App() {
  return (
    <div className="bg-zinc-950">
      <Header />
      <main>
                {/* New Hero section with full height */}
                <div className="h-screen flex flex-col justify-between">
          <div className="relative isolate overflow-hidden flex-grow">
            <div className="mx-auto max-w-7xl px-6 lg:px-8 h-full">
              <div className="flex flex-col lg:flex-row items-center h-full py-24">
                <div className="flex-1 max-w-xl lg:max-w-none">
                  <img src="/vtuberlogo.png" alt="Lunchcat logo" className="h-24 sm:h-32 lg:h-40 mb-8" />
                  <motion.h1 
                    className="text-2xl sm:text-3xl lg:text-4xl font-mono font-bold text-white mb-6"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    Secure your apps.
                    <br />
                    <span className="text-indigo-400">Ship with confidence.</span>
                  </motion.h1>
                  <motion.p 
                    className="mt-6 text-lg leading-8 text-gray-300"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    The AI-powered security suite you neve
                    Get instant feedback, continuous monitoring, and peace of mind.
                  </motion.p>
                  <motion.div 
                    className="mt-10 flex items-center gap-x-6"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                  >
                    <Popup trigger={
                      <a href="#" className="rounded-md bg-indigo-500/30 border border-indigo-500/40 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">
                        Join waitlist
                      </a>
                    }
                    modal
                    nested>
                      <Waitlist />
                    </Popup>
                    <a href="https://docs.lunchcat.dev" className="text-sm font-mono text-gray-300 hover:text-white">
                      Documentation <span className="text-indigo-500">→</span>
                    </a>
                  </motion.div>
                </div>

                {/* Animated Product Preview */}
                <motion.div 
                  className="flex-1 mt-16 lg:mt-0 lg:ml-16"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.7 }}
                >
                  <div className="relative">
                    <div className="absolute inset-0 bg-gradient-to-t from-zinc-950 to-transparent z-10 pointer-events-none" />
                    <img
                      src="/product.png"
                      alt="Product preview"
                      className="rounded-lg shadow-2xl ring-1 ring-white/10"
                    />
                    <div className="absolute -top-4 -right-4">
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
        {/* Existing sections */}
        <Features />
        <ParallaxSection />
        {/* <HorizontalFeatureScroll />*/}
        <StatsSection />
        <FeaturesAlt />
        
        <FloatingFeatures />
        <SplitScreenScroll />
        {/* Footer */}
                  {/* Footer */}
                  <footer className="mt-12 border-t border-white/10 bg-black/30 backdrop-blur-xl">
            <div className="mx-auto max-w-7xl px-6 py-4 md:flex md:items-center md:justify-between">
              <div className="flex justify-start space-x-6 md:order-2">
                <TypeAnimation
                  sequence={[
                    '> scanning for vulnerabilities...',
                    1000,
                    '> analyzing dependencies...',
                    1000,
                    '> monitoring systems...',
                    1000,
                    '> all systems secure',
                    1000,
                  ]}
                  wrapper="span"
                  speed={50}
                  className="font-mono text-sm text-indigo-500"
                  repeat={Infinity}
                />
              </div>
              <nav className="mt-4 md:mt-0">
                <ul className="flex space-x-6">
                  <li>
                    <a href="https://x.com/joinlunchcat" className="font-mono text-sm text-gray-400 hover:text-white">
                      <span className="text-indigo-500">$</span> twitter
                    </a>
                  </li>
                  <li>
                    <a href="mailto:jobs@lunchcat.dev" className="font-mono text-sm text-gray-400 hover:text-white">
                      <span className="text-indigo-500">$</span> careers
                    </a>
                  </li>
                  <li>
                    <a href="https://github.com/lunchcat" className="font-mono text-sm text-gray-400 hover:text-white">
                      <span className="text-indigo-500">$</span> github
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </footer>
      </main>
    </div>
  )
}