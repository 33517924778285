import './App.css';
import { motion, useMotionValue, useMotionTemplate } from "framer-motion";
import { TypeAnimation } from 'react-type-animation';

export default function App() {
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  function handleMouseMove({ clientX, clientY, currentTarget }) {
    const { left, top } = currentTarget.getBoundingClientRect();
    mouseX.set(clientX - left);
    mouseY.set(clientY - top);
  }

  return (
    <div 
      className="bg-white min-h-screen relative"
      onMouseMove={handleMouseMove}
    >
      {/* Base pattern */}
      <div 
        className="fixed inset-0 z-5 opacity-50 pattern-grid"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23000000' fill-opacity='0.08'%3E%3Cpath d='M36 34v-2h-1v2h-2v1h2v2h1v-2h2v-1h-2zm0-30V2h-1v2h-2v1h2v2h1V5h2V4h-2zM6 34v-2H5v2H3v1h2v2h1v-2h2v-1H6zM6 4V2H5v2H3v1h2v2h1V5h2V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        }}
      />

      {/* Animated overlay pattern */}
      <motion.div 
        className="fixed inset-0 z-6 opacity-50 pattern-grid"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23000000' fill-opacity='0.08'%3E%3Cpath d='M36 34v-2h-1v2h-2v1h2v2h1v-2h2v-1h-2zm0-30V2h-1v2h-2v1h2v2h1V5h2V4h-2zM6 34v-2H5v2H3v1h2v2h1v-2h2v-1H6zM6 4V2H5v2H3v1h2v2h1V5h2V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
          opacity: useMotionTemplate`${(mouseX.get() + mouseY.get()) / 10000}`,
          rotate: useMotionTemplate`${(mouseX.get() + mouseY.get()) / 100}deg`,
        }}
      />

      {/* Top scrolling text */}
      <div className="fixed top-0 w-full bg-black text-white py-2 z-50">
        <div className="scroll-text font-mono text-sm">
          "CYBERSECURITY" "ARTIFICIAL INTELLIGENCE" "NEURAL DEFENSE" "QUANTUM RESILIENCE" "ADAPTIVE SYSTEMS" "SECURE AI" "AUTONOMOUS PROTECTION" "INTELLIGENT SAFEGUARDS"
        </div>
      </div>

      {/* Bottom scrolling text */}
      <div className="fixed bottom-0 w-full bg-black text-white py-2 z-50">
        <div className="scroll-text-reverse font-mono text-sm">
          "ESTABLISHED 2023" "MADE IN SAN FRANCISCO" "SECURING THE FUTURE" "DEFENSE AT SCALE" "AI-POWERED PROTECTION" "ZERO-DAY READY"
        </div>
      </div>

      <main className="h-screen flex flex-col justify-between pt-12 pb-12">
        <div className="relative isolate overflow-hidden flex-grow">
          <div className="mx-auto max-w-7xl px-6 lg:px-8 h-full">
            <div className="flex flex-col istems-center justify-center h-full">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="text-center"
              >
                <img 
                  src="/logo.svg" 
                  alt="Lunchcat logo" 
                  className="h-24 sm:h-32 lg:h-40 mb-12 mx-auto invert" 
                />
                
                <div className="mt-16 flex justify-center gap-x-8">
                  <a href="https://x.com/joinlunchcat" 
                    className="text-sm font-mono text-black hover:text-primary-600 transition-all relative group">
                    <span className="absolute -inset-x-4 -inset-y-2 border border-transparent group-hover:border-primary-200 transition-all"></span>
                    twitter
                  </a>
                  <a href="mailto:hello@lunchcat.dev" 
                    className="text-sm font-mono text-black hover:text-primary-600 transition-all relative group">
                    <span className="absolute -inset-x-4 -inset-y-2 border border-transparent group-hover:border-primary-200 transition-all"></span>
                    careers
                  </a>
                  <a href="https://github.com/lunchcat" 
                    className="text-sm font-mono text-black hover:text-primary-600 transition-all relative group">
                    <span className="absolute -inset-x-4 -inset-y-2 border border-transparent group-hover:border-primary-200 transition-all"></span>
                    open-source
                  </a>
                </div>
              </motion.div>
            </div>
          </div>
        </div>

        <div className="fixed left-4 top-1/2 -translate-y-1/2 transform rotate-[-90deg] origin-left">
          <span className="font-mono text-xs text-black">
            "LUNCHCAT, INC"
          </span>
        </div>

        <div className="fixed right-4 top-1/2 -translate-y-1/2 transform rotate-90 origin-right">
          <span className="font-mono text-xs text-black">
            "VERSION 0.0.3"
          </span>
        </div>
      </main>

      <div className="fixed bottom-12 left-4 font-mono text-xs">
        <div className="border border-black p-2 bg-white">
          <div>"TECHNICAL SPECIFICATIONS"</div>
          <div>VERSION: "0.0.3"</div>
          <div>PLATFORM: "SIF"</div>
          <div>STATUS: "DEVELOPMENT"</div>
        </div>
      </div>
    </div>
  );
}